<template>
  <LayoutPage title="Reportes de reversiones">
    <!-- filtros table  -->
    <v-expansion-panels v-model="panel" class="mb-3">
      <v-expansion-panel :key="0">
        <v-expansion-panel-header class="outline_none" disable-icon-rotate>
          <template v-slot:actions>
            <span class="primary--text text-subtitle-1 mt-0">Filtros</span>
            <v-icon size="33" color="success"> search </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- 1 fila de filtros -->
          <v-row class="pt-0 mx-5">
            <v-col cols="6" md="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de inicio"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaInicio)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaInicio"
                  locale="es-ar"
                  no-title
                  scrollable
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="ml-5"
                    label="Fecha de fin"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    persistent-hint
                    :value="formatDate(fechaFin)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaFin"
                  locale="es-ar"
                  no-title
                  scrollable
                  :min="fechaInicio"
                  :max="new Date().toISOString().substr(0, 10)"
                  @change="
                    {
                      page = 1;
                      getData();
                    }
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!--end filtros-->

    <v-data-table
      :headers="headers"
      :items="reversiones.filter((rev) => rev.Fecha)"
      :loading="loading"
      no-data-text="Sin registros para mostrar"
      loading-text="Cargando..."
      hide-default-footer
      @page-count="pageCount = $event"
      :page.sync="page"
      :items-per-page="rowsPerPage"
      :item-class="validarFecha"
    >
      <template v-slot:[`item.Nombre`]="{ item }">
        {{ getNombreArchivo(item.Nombre) + ".xlsx" }}
      </template>
      <template v-slot:[`item.TotalMonto`]="{ item }">
        {{ formatImport(item.TotalMonto) }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="green darken-3"
              @click="downloadExcel(item)"
              v-on="on"
              ><v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
          </template>
          <span>Descargar Excel</span>
        </v-tooltip>
        <v-tooltip bottom> </v-tooltip>

        <!-- descargar pdf -->
        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="red darken-3"
              @click="generatePDF(item)"
              v-on="on"
              ><v-icon>mdi-file-download</v-icon>
            </v-btn>
          </template>
          <span>Descargar PDF</span>
        </v-tooltip>

        <v-tooltip bottom color="black">
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="grey darken-3"
              @click="showPreview(item)"
              v-on="on"
              ><v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>Vista previa</span>
        </v-tooltip>
      </template>

      <template v-slot:footer>
        <v-row class="no-gutters mt-2 mx-2">
          <v-flex xs12>
            <span>Mostrar</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn dark text color="primary" class="ml-2" v-on="on">
                  {{ rowsPerPage }}
                  <v-icon>arrow_drop_down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in rowsPerPageArray"
                  :key="index"
                  @click="rowsPerPage = number"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-flex>
        </v-row>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>

    <div id="pdf-preview"></div>

    <!-- PREVIEW REPORTE -->
    <v-dialog
      v-model="preview"
      max-width="85%"
      class="white"
      style="z-index: 14000 !important;"
      @click:outside="tab = 0"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-bold ">
          REPORTE DE REVERSIONES
          <v-spacer />
          <span class="body-1 grey--text text--darken-3">
            Fecha: {{ previewData.fecha }}
          </span>
        </v-card-title>
        <v-divider class="ma-0"></v-divider>
        <v-tabs
          show-arrows
          v-model="tab"
          hide-slider
          v-if="previewData.items.length > 1"
          class="mb-4 px-2 mt-2 "
        >
          <!-- <v-tabs-slider color="grey darken-3"></v-tabs-slider> -->
          <v-tab
            v-for="(item, i) in previewData.items"
            :key="i"
            active-class="white--text tab-active rounded-pill"
          >
            Operación {{ i + 1 }}
          </v-tab>
        </v-tabs>

        <p class="mb-0 ma-3 text-start body-2 blue--text font-weight-bold">
          CABECERA
        </p>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">FECHA</th>
                <th class="text-left">ID PAGO</th>
                <th class="text-left">REFERENCIA EXTERNA</th>
                <th class="text-left">ESTADO</th>
                <th class="text-left">TRANSACCIÓN ID</th>
                <th class="text-left">FECHA DE PAGO INTENTO</th>
                <th class="text-left">IMPORTE</th>
                <th class="text-left">MONTO REVERTIDO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ previewData.fecha }}</td>
                <td>{{ previewData.items[tab].IdPago }}</td>
                <td>{{ previewData.items[tab].ReferenciaExterna }}</td>
                <td>{{ previewData.items[tab].PagoEstado }}</td>
                <td>{{ previewData.items[tab].IntentoPago.IdTransaccion }}</td>
                <td>
                  {{ fechaPago }}
                </td>
                <td>
                  {{
                    formatImport(
                      previewData.items[tab].IntentoPago.ImportePagado
                    )
                  }}
                </td>
                <td>{{ formatImport(previewData.items[tab].Monto) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mb-0 ma-3 text-start body-2 green--text font-weight-bold">
          DETALLE
        </p>
        <v-data-table
          :headers="headerPreview"
          :items="dataDetails"
          hide-default-footer
          disable-pagination
          disable-sort
          no-data-text="Sin registros para mostrar"
          class="pb-5"
        >
          <template v-slot:[`item.Monto`]="{ item }">
            {{ formatImport(item.Monto) }}
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <!-- Modal de Fechas -->
    <v-dialog v-model="RestriccionFecha" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          <v-col>
            <v-row
              ><div class="flex justify-center">
                <v-icon x-large color="red">mdi-close-octagon</v-icon>
              </div></v-row
            >
            <v-row></v-row>
            <v-row>
              <div class="flex justify-center">
                <h1>Rango de Fechas</h1>
              </div>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text>No puede ser mayor a 31 dias</v-card-text>
        <v-card-actions>
          <div class="flex justify-center">
            <v-btn
              color="green darken-1"
              class="text-white"
              small
              @click="closeModal"
            >
              Aceptar
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </LayoutPage>
</template>

<script>
import moment from "moment";
import LayoutPage from "../../../components/LayoutPage.vue";
import formatDate from "../../../utils/formatDate";
import formatImport, { formatImportFloat } from "../../../utils/formatImport";
import reportesService from "../../../services/reportes";
import { mapGetters } from "vuex";

import * as XLSX from "xlsx-js-style";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import PDFObject from "pdfobject";
import { fonts } from "@/utils/fontsPDF.js";
import { dataEncabezadoPDF } from "../../../utils/dataEncabezadoPDF";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = fonts;

export default {
  name: "ReporteReversiones",
  components: { LayoutPage },
  data: () => ({
    RestriccionFecha: false,
    panel: 0,
    fechaInicio: moment()
      .subtract(1, "months")
      .format("YYYY-MM-DD"),
    fechaFin: moment().format("YYYY-MM-DD"),

    menu1: false,
    menu2: false,
    headers: [
      { text: "FECHA", value: "Fecha", sortable: false },
      { text: "NOMBRE", value: "Nombre", sortable: false },
      {
        text: "OPERACIONES",
        value: "CantidadOperaciones",
        sortable: false,
        align: "center",
      },
      { text: "MONTO TOTAL", value: "TotalMonto", sortable: false },
      {
        text: "ACCIONES",
        value: "actions",
        sortable: false,
        align: "center",
      },
    ],

    reversiones: [],
    loading: false,
    preview: false,
    previewData: { fecha: "", items: [] },
    headerPreview: [
      { text: "CUENTA", value: "Cantidad" },
      { text: "DESCRIPCIÓN", value: "Descripcion" },
      { text: "IDENTIFICADOR", value: "Identificador" },
      { text: "MONTO", value: "Monto" },
    ],

    rowsPerPageArray: [10, 30, 50],
    rowsPerPage: 10, // cantidad de registros que se muestran en la tabla
    page: 1, // pagina actual de la tabla
    pageCount: 0, // cantidad de paginas

    tab: 0,

    dataExcelExport: [],

    formatDate,
    formatImport,
    formatImportFloat,
  }),

  // si el usuario cambia de cliente, volver a realizar la llamada a la api
  watch: {
    getCurrentClientId() {
      this.getData();
    },
    getCurrentAccountId() {
      this.getData();
    },
  },

  computed: {
    ...mapGetters([
      "getCurrentClientId",
      "getCurrentAccountId",
      "CLIENTE_CUENTA_DATA",
    ]),
    daysDifference() {
      return moment(this.fechaFin).diff(moment(this.fechaInicio), "days");
    },

    dataDetails() {
      const previewData = this.previewData.items;

      if (previewData.length > 0) return previewData[this.tab].Items;
      else return [];
    },

    fechaPago() {
      const fecha = this.previewData.items[this.tab].IntentoPago.FechaPago;

      return moment(fecha.substr(0, 10)).format("DD-MM-YYYY");
    },
  },

  mounted() {
    this.$nextTick(() => this.getData());
  },

  methods: {
    closeModal() {
      this.RestriccionFecha = false;
    },
    async getData() {
      if (this.daysDifference > 31) {
        this.RestriccionFecha = true;
        return;
      }
      try {
        this.loading = true;
        this.menu1 = false;
        this.menu2 = false;

        const params = {
          FechaInicio: moment(this.fechaInicio).format("DD-MM-YYYY"),
          FechaFin: moment(this.fechaFin).format("DD-MM-YYYY"),
          ClienteId: this.getCurrentClientId,
          CuentaId: this.getCurrentAccountId,
        };

        const data = await reportesService.reporteReversiones(params);

        this.reversiones = data?.data?.DetallesReversiones || [];
        this.loading = false;
      } catch (error) {
        // console.error(error);
        this.loading = false;
      }
    },

    async downloadExcel(item) {
      const workbook = XLSX.utils.book_new();

      item.Reversiones.map(({ PagoRevertido, Monto }, index) => {
        const data = [
          [
            {
              v: "FECHA",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "ID PAGO",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "REFERENCIA EXTERNA",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "ESTADO",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "TRANSACCION ID",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "FECHA PAGO INTENTO",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "IMPORTE $",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "MONTO REVERTIDO $",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
          ],

          [
            item.Fecha,
            PagoRevertido.IdPago,
            PagoRevertido.ReferenciaExterna,
            PagoRevertido.PagoEstado,
            PagoRevertido.IntentoPago.IdTransaccion,
            formatDate(PagoRevertido.IntentoPago.FechaPago),
            formatImport(PagoRevertido.IntentoPago.ImportePagado, true),
            formatImport(Monto)
              .replace("$", "")
              .replace(/[\s]/g, ""),
          ],
          [""],
          [
            {
              v: "",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "CANTIDAD",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "DESCRIPCIÓN",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "IDENTIFICADOR",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
            {
              v: "MONTO $",
              t: "s",
              s: {
                font: { bold: true, color: { rgb: "FFFFFF" } },
                fill: { fgColor: { rgb: "1F497D" } },
              },
            },
          ],
        ];

        PagoRevertido.Items.forEach((subrow) =>
          data.push([
            "",
            subrow.Cantidad,
            subrow.Descripcion,
            subrow.Identificador,
            formatImport(subrow.Monto, true),
          ])
        );

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 10 },
          { wch: 10 },
          { wch: 20 },
          { wch: 32 },
          { wch: 36 },
          { wch: 20 },
          { wch: 12 },
          { wch: 20 },
        ];
        XLSX.utils.book_append_sheet(
          workbook,
          worksheet,
          `Operacion-${index + 1}`
        );
      });

      const nombre = item.Nombre.replaceAll(" ", "-").replaceAll(".", "-");
      XLSX.writeFile(workbook, `${nombre}-REVERSION.xlsx`);
    },

    validarFecha(item) {
      if (!item.Fecha) return "hidden";
    },

    showPreview(item) {
      const data = item.Reversiones.map((r) => {
        r.PagoRevertido.Monto = r.Monto;
        return r.PagoRevertido;
      });

      this.preview = true;
      this.previewData = {
        fecha: item.Fecha,
        items: data,
      };
    },
    generatePDF(item) {
      const contenido = [
        item.Reversiones.map((reversion, index) => [
          dataEncabezadoPDF(this.CLIENTE_CUENTA_DATA),
          {
            text: "REVERSIONES DE PAGOS",
            style: "header",
            alignment: "center",
            bold: true,
            margin: [0, 30, 0, 0],
          },
          {
            text: `Fecha: ${item.Fecha}`,
            style: "subheader",
            alignment: "right",
            italics: true,
            font: "Roboto",
          },
          {
            table: {
              widths: ["*", "*", "*"],
              headerRows: 1,
              body: [
                //fila 1
                [
                  { text: `Referencia Externa: ${reversion.Id}`, bold: true },
                  { text: `Medio de Pago: ${reversion.MedioPago}`, bold: true },
                  {
                    text: `Monto:  $${formatImport(reversion.Monto, true)}`,
                    bold: true,
                  },
                ],
                //fila 2
                [
                  {
                    stack: [
                      {
                        text: "Pago",
                        alignment: "center",
                        bold: true,
                      },
                      {
                        text: `Id: ${reversion.PagoRevertido.IdPago}`,
                        style: "bodyTable1",
                      },
                      {
                        text: `Estado: ${reversion.PagoRevertido.PagoEstado}`,
                        style: "bodyTable1",
                      },
                    ],
                  },
                  {
                    colSpan: 2, //combinar dos columnas
                    stack: [
                      {
                        text: "Intento",
                        alignment: "center",
                        bold: true,
                      },
                      {
                        text: `Id Intento: ${reversion.PagoRevertido.IntentoPago.IdIntentoPago}`,
                        style: "bodyTable1",
                      },
                      {
                        text: `Transaccion: ${reversion.PagoRevertido.IntentoPago.IdTransaccion}`,
                        style: "bodyTable1",
                      },
                      {
                        text: `Fecha: ${formatDate(
                          reversion.PagoRevertido.IntentoPago.FechaPago
                        )}`,
                        style: "bodyTable1",
                      },
                      {
                        text: `Importe: $ ${formatImport(
                          reversion.PagoRevertido.IntentoPago.ImportePagado,
                          true
                        )}`,
                        style: "bodyTable1",
                      },
                    ],
                  },
                  "", //tercer columna vacia
                ],
              ],
            },
          },
          {
            table: {
              widths: ["*"],
              headerRows: 1,
              body: [
                [
                  {
                    text: "Detalle de Pagos",
                    style: "headerDetalleTable",
                    border: [false, true, false, true],
                  },
                ],
              ],
            },
          },
          {
            table: {
              widths: ["auto", "auto", "auto", "*"], //ancho de las columnas
              headerRows: 1,
              body: [
                //fila 1
                [
                  {
                    text: "Cantidad",
                    style: "table2Header",
                    alignment: "center",
                  },
                  {
                    text: "Descripcion",
                    style: "table2Header",
                    alignment: "center",
                  },
                  {
                    text: "Identificador",
                    style: "table2Header",
                    alignment: "center",
                  },
                  {
                    text: "Monto",
                    style: "table2Header",
                    alignment: "center",
                  },
                ],
                //fila 2, n cantidad de filas
                ...reversion.PagoRevertido.Items.map((pagorev) => [
                  {
                    text: pagorev.Cantidad,
                    alignment: "center",
                    italics: true,
                  },
                  {
                    text: pagorev.Descripcion,
                    alignment: "center",
                    italics: true,
                  },
                  {
                    text: pagorev.Identificador,
                    alignment: "center",
                    italics: true,
                  },
                  {
                    text: `$ ${formatImport(pagorev.Monto, true)}`,
                    alignment: "center",
                    italics: true,
                  },
                ]),
              ],
            },
            layout: {
              hLineWidth: (i) => (i === 1 ? 0 : 0.3),
              vLineWidth: () => 0,
              hLineColor: () => "#80DDCD",
              vLineColor: () => "black",
              hLineStyle: (i) => {
                if (i === 0) return null;

                return { dash: { length: 10, space: 4 } }; //linea punteada
              },
            },
            pageBreak: index === item.Reversiones.length - 1 ? null : "after", //salto de pagina
          },
        ]),
      ];
      const styles = {
        header: {
          fontSize: 10,
          margin: [0, 5, 0, 0],
          font: "Roboto",
        },
        subheader: {
          fontSize: 11,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        table2Header: {
          bold: true,
          italics: true,
          fontSize: 11,
          color: "#640000",
          margin: [0, 5, 0, 5],
        },
        bodyTable1: {
          fontSize: 10,
          margin: [0, 5, 0, 5],
        },
        headerDetalleTable: {
          bold: true,
          fillColor: "#424242",
          color: "#FFFFFF",
          margin: [0, 5, 0, 5],
          fontSize: 11,
          font: "Roboto",
        },
      };

      const images = {
        wee: `${window.location.origin}/assets/img/wee_logo.png`,
      };

      const docDefinition = {
        content: contenido,
        styles: styles,
        images: images,
        defaultStyle: {
          font: "CourierPrime",
        },
      };

      // PREVIEW
      /* var pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.getBlob((blob) => {
        var pdfUrl = URL.createObjectURL(blob);
        PDFObject.embed(pdfUrl, "#pdf-preview");
      }); */

      // DESCARGAR
      pdfMake
        .createPdf(docDefinition)
        .download(this.getNombreArchivo(item.Nombre));
    },

    getNombreArchivo(nombre) {
      return `${nombre.replaceAll(" ", "-").replaceAll(".", "-")}-REVERSION`;
    },
  },
};
</script>

<style scoded>
.v-tooltip__content {
  width: 120px;
}

.tab-active {
  background-image: linear-gradient(
    10deg,
    hsl(195deg 100% 46%) 0%,
    hsl(191deg 100% 44%) 10%,
    hsl(184deg 100% 40%) 20%,
    hsl(175deg 100% 38%) 30%,
    hsl(162deg 73% 44%) 40%,
    hsl(130deg 44% 58%) 50%,
    hsl(112deg 47% 59%) 60%,
    hsl(97deg 54% 57%) 70%,
    hsl(85deg 60% 54%) 80%,
    hsl(74deg 66% 50%) 90%,
    hsl(65deg 99% 42%) 100%
  );
}
</style>
