const baseUrl = window.location.origin;
const baseRoboto =
  "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto";

export const fonts = {
  CourierPrime: {
    normal: `${baseUrl}/assets/fonts/CourierPrime-Regular.ttf`,
    bold: `${baseUrl}/assets/fonts/CourierPrime-Bold.ttf`,
    italics: `${baseUrl}/assets/fonts/CourierPrime-Italic.ttf`,
    bolditalics: `${baseUrl}/assets/fonts/CourierPrime-BoldItalic.ttf`,
  },
  Roboto: {
    normal: `${baseRoboto}/Roboto-Regular.ttf`,
    bold: `${baseRoboto}/Roboto-Medium.ttf`,
    italics: `${baseRoboto}/Roboto-Italic.ttf`,
    bolditalics: `${baseRoboto}/Roboto-MediumItalic.ttf`,
  },
};
